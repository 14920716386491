@import '../../../imports.scss';

.categoryList{
  list-style: none;
  margin-top: 15px;

  & > li{
    line-height: 1.5em;
    display: block;
    margin-bottom: 20px;

    & > a{
      font-family: $font-heading;
      font-size: 18px;
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
    }
  }

  & > li > ul > li{
    display: block;

    & > a{
      line-height: 2em;
    }
  }

  a{
    color: $color-black;
    transition: all .1s linear;

    &.highlighted{
      color: $color-primary;
      font-weight: bold;
      cursor: default;
    }

    &:hover{
      color: $color-primary;
    }
  }
}