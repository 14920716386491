@import '../../imports.scss';

.collectionSection{
  padding-top: 50px;

  @include mobile(){
    padding-top: 30px;
  }
}

.filterActions{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  & > div{
    width: 48%;
  }

  .sortingMobile{
    display: none;

    @include mobile(){
      display: block;
    }

    select{
      width: 100%;
      height: 44px;
      background: $color-black;
      color: $color-white;
      padding: 0 15px;
      appearance: none;
      font-family: $font-heading;
      letter-spacing: 1px;
      font-weight: bold;
      text-align: center;
      text-align-last:center;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}

.sorting{
  width: 25% !important;
  border-right: 0 !important;

  @include mobile(){
    display: none;
    width: 100% !important;
  }
}

.filterBtn{
  span{
    @include mobile(){
      width: 100%;
      background: $color-black;
      line-height: 30px;
      font-size: 14px !important;
      font-family: $font-heading;
    }
  }
}

.searchResultText{
  text-align: left !important;
  width: 75% !important;

  @include mobile(){
    width: 100% !important;
    text-align: center !important;
  }
}

.cover{
  text-align: center;

  img{
    max-width: 100%;
  }
}

.coverTitle{
  text-align: center;
  font-family: $font-heading;
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
}

.coverDesc{
  text-align: center;
  max-width: 90%;
  width: 500px;
  margin: 0 auto 20px auto;
  line-height: 1.5em;
}