$color-primary: #e73b27;
$color-black: #1f1f1f;
$color-gray: #777777;

$color-white: #ffffff;

$font-heading: "DM Sans", sans-serif;
$font-text: "Lato", sans-serif;

@mixin text-shadow ($color: rgba(32,33,36,0.7)){
  text-shadow: 1px 1px 6px $color;
}

@mixin mobile() {
  @media (max-width: 991px) {
    @content;
  }
}