@import '../../imports.scss';

.mobile{
  display: none;

  @include mobile(){
    display: flex;
  }

}

.menu{
  @include mobile(){
    max-width: 100%;
    overflow: auto;
    flex-flow: row nowrap;
    margin-bottom: 20px;
  }

  li{
    @include mobile(){
      margin-right: 10px;
    }

    &.active{
      a{
        @include mobile(){
          background: $color-primary;
        }
      }
    }

    a{
      @include mobile(){
        display: inline-block;
        background: $color-black;
        color: $color-white;
        white-space: nowrap;
        padding: 10px 20px;
      }
    }
  }
}
