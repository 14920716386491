@import '../imports.scss';

.coverSection{
  padding-top: 0;
  text-align: center;

  img{
    max-width: 100%;
  }
}

.titleSection{
  text-align: center;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;

  h1{
    font-family: $font-heading;
  }

  p{
    font-family: $font-text;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8em;
  }
}

.showroomSection{
  padding-top: 50px;
}

.region{
  margin-bottom: 50px;
}

.regionTitle{
  font-family: $font-heading;
  font-weight: bold;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.showrooms{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.showroom{
  width: 48%;
  margin-bottom: 20px;
  font-family: $font-text;
}

.showroomName{
  font-size: 18px;
  font-weight: bold;
}

.showroomContact{
  font-weight: bold;
}

.showroomAddress{

}

.mapsBtn{
  margin: 16px 0;

  a{
    background: $color-black;
    color: $color-white;
    padding: 8px 16px;

    @include mobile(){
      display: block;
      text-align: center;
    }

    &:hover{
      opacity: 0.95;
    }
  }
}

.shippingInfo{
  text-align: center;

  .title{
    font-family: $font-heading;
    text-transform: none;
  }

  .icons{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .iconItem{
    display: flex;
    flex-flow: column nowrap;
    margin: 50px 120px;
    align-items: center;
    position: relative;

    @include mobile(){
      margin: 50px;
    }

    &:first-child:after{
      content: '';
      position: absolute;
      right: -120px;
      top: 0;
      width: 2px;
      height: 100%;
      background: rgba(0,0,0,0.1);

      @include mobile(){
        right: -50px;
      }
    }

    img{
      max-width: 100%;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }

    .iconTitle{
      font-family: $font-heading;
      font-weight: bold;
      font-size: 24px;
      color: $color-black;
    }

    .iconSubtitle{
      color: $color-gray;
    }
  }
}