@import '../../../imports.scss';

.productName{
  font-size: 16px;
  line-height: 1.3em;
}

.productDiscount{
  font-size: 14px;
  font-weight: bold;
  color: $color-primary;
}

.productPrice{
  font-size: 18px !important;
  margin-top: 5px;
}

.size{
  border: 1px solid rgba(0,0,0,0.2) !important;
  border-radius: 0 !important;
  background: $color-black;

  a{
    font-size: 14px !important;
    transition: none;
    color: $color-white !important;
  }
}

.selectedSize{
  border: 1px solid $color-primary !important;
  background: $color-primary;

  a{
    color: white !important;
    cursor: default;
  }
}

.modalBody{
  padding: 50px 30px 20px 30px !important;
}

h2.modalProductName{
  font-size: 21px;
  font-weight: normal;
  font-family: $font-heading;
  line-height: 1.3em;
  text-transform: none;
  margin-bottom: 5px;
}