@import '../../../imports.scss';

.footerSection{
  @include mobile(){
    margin-bottom: 0;
  }

  .borderSection{
    @include mobile(){
      border: 0;
    }
  }
}

.float{
  position:fixed;
  width:120px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color: $color-black; //#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size: 14px;
  line-height: 60px;
  //box-shadow: 2px 2px 3px #999;
  z-index:9;

  &:hover{
    color: white;
  }

  @include mobile(){
    display: none;
  }
}

.myFloat{
  margin-top:16px;
  font-size:30px;
  vertical-align: sub;
}



.aboutContentWrapper{
  @include mobile(){
    padding: 30px 0;
  }
}


.footerTitle{
  margin-top: 50px;

  h4{
    margin-bottom: 10px !important;
    font-family: $font-heading;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 2px solid rgba(0,0,0,0.1);
    padding-bottom: 12px;
  }
}

.footerContent{
  color: $color-black !important;

  p{
    color: $color-black !important;
  }

  ul{
    li{
      margin-bottom: 5px;
      padding-top: 0 !important;
      color: $color-black !important;

      i{
        top: 8px !important;
        color: $color-black !important;

        @include mobile(){
          top: 1px !important;
        }
      }

      a{
        color: $color-black !important;
        transition: all .1s linear;
        line-height: 1em;

        &:before{
          display: none;
        }

        &:hover{
          color: $color-primary !important;
        }
      }
    }
  }
}